import classes from './Login.module.css';
import LoginComponent from '../../components/Login';
import RegisterComponent from '../../components/Register';
import ResetPasswordComponent from '../../components/ResetPassword';

function Login({ type }: { type: string }) {
	switch (type) {
		case 'login':
			return <LoginComponent />;
		case 'register':
			return <RegisterComponent />;
		case 'resetpassword':
			return <ResetPasswordComponent />;
		default:
			return <LoginComponent />;
	}
}

export default Login