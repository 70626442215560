import { Loader, Notification } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Error404 from '../components/Error404';
import Header from '../components/Header';
import Home from './Home';

import classes from './index.module.css';
import { IUser } from '../utils/types';
import Login from './Login';
import Kills from './Kills';
import Leaderboard from './Leaderboard';
import ToolId from './ToolId';

interface IResponse {
	error?: string;
	user?: IUser;
}

async function fetchMe(): Promise<IResponse> {
	/* dev */
	const token = localStorage.getItem('token');

	try {
		const response = await fetch('https://bycop.fr:3638/me', {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				token: localStorage.getItem('token'),
			})
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
		return { error: 'An error occured while fetching your account' };
	}
}

export default function App() {

	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IUser | null>(null);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		if (localStorage.getItem('token')) {
			fetchMe().then((data) => {
				if (data.error) {
					console.error(data.error);
					setUser(null);
					setError(data.error);
					localStorage.removeItem('token');
					return;
				}
				else if (data.user) {
					setUser(data.user);
					setError("");
				}
			}).finally(() => {
				setIsLoading(false);
			});
		} else {
			setIsLoading(false);
		}
	}, []);

	return (
		!isLoading ? (
			<div className={classes.App}>
				<Header user={user} />
				{error !== "" && (
					<Notification className={classes.Notification} color="red" title="Error" onClose={() => setError("")}>
						{error}
					</Notification>
				)}
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/kills" element={<Kills user={user} />} />
					<Route path="/leaderboard" element={<Leaderboard user={user} />} />
					<Route path="/toolid" element={<ToolId user={user} />} />
					<Route path="/login" element={<Login type="login" />} />
					<Route path="/register" element={<Login type="register" />} />
					<Route path="/reset-password" element={<Login type="resetpassword" />} />
					<Route path="*" element={<Error404 />} />
				</Routes>
			</div>
		) : (
			<div className={classes.Loader}>
				<Loader color="blue" type="bars" />
			</div>
		)
	);
}
