import classes from './Error404.module.css'

function Error404() {
    return (
        <div className={classes.Error}>
            <h1>Error 404: Page not found</h1>
        </div>
    )
}
 
export default Error404