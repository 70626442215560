import { Grid, Textarea, Tooltip } from '@mantine/core';
import classes from './ToolId.module.css';
import { useEffect, useState } from 'react';
import { IUser } from '../../utils/types';
import Error401 from '../../components/Error401';
import { IconFileExport } from '@tabler/icons-react';


export default function ToolId({ user = null }: { user: IUser | null }) {
	const [ids, setIds] = useState<string>("");
	const [exportedIds, setExportedIds] = useState<string>("");

	useEffect(() => {
		const regex = /(\d{10,})/g;
		const matches = ids.match(regex);

		if (matches) {
			setExportedIds("<@" + matches.join(">\n<@") + ">");
		}
	}, [ids])

	if (!user?.username)
		return <Error401 />;

	return (
		<div className={classes.Tool}>
			<h1>Export IDS</h1>

			<Grid className={classes.Content}>
				<Grid.Col className={classes.Side} span={{ base: 12, xs: 4 }}>
					<Textarea
						label="Liste des id"
						required
						value={ids}
						onChange={(event) => setIds(event.currentTarget.value)}
						autoComplete="nope"
					/>
				</Grid.Col>
				<Grid.Col className={classes.List} span={{ base: 12, xs: 8 }} h={400}>
					<Tooltip label="Export to discord" color="teal">
						<IconFileExport onClick={
							() => {
								navigator.clipboard.writeText(exportedIds);
							}
						} />
					</Tooltip>
					<Textarea
						placeholder="Input placeholder"
						value={exportedIds}
						readOnly={true}
					/>
				</Grid.Col>
			</Grid>
		</div>
	);
}