import { Anchor, Button, Checkbox, Grid, Group, Notification, NumberInput, Progress, ScrollArea, Select, Table, Text, TextInput, Tooltip } from '@mantine/core';
import classes from './Leaderboard.module.css';
import { useEffect, useState } from 'react';
import { IconFileExport } from '@tabler/icons-react';
import { IUser } from '../../utils/types';
import Error401 from '../../components/Error401';

interface IPlayer {
	killer_name: string;
	kills: number;
}

interface IGlobalData {
	total_kills: number;
}

interface IData {
	leaderboard: IPlayer[];
	globalData: IGlobalData;
}

function fillDummyData(setData: React.Dispatch<React.SetStateAction<IData>>) {
	const leaderboard: IPlayer[] = [
		{ killer_name: "Bycop", kills: 150 },
		{ killer_name: "Mistik", kills: 100 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Zywoo", kills: 50 },
		{ killer_name: "Pichot", kills: 25 }
	];

	const globalData: IGlobalData = {
		total_kills: leaderboard.reduce((acc, curr) => acc + curr.kills, 0)
	};

	setData({ leaderboard, globalData });
}

function getLeaderboard(setData: React.Dispatch<React.SetStateAction<IData>>, setError: React.Dispatch<React.SetStateAction<string>>, type: string, match: number | string, crew: string) {
	if (type === "") {
		setError("Type is required");
		return;
	}

	fetch("https://bycop.fr:3638/leaderboard", {
		method: "POST",
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			type: type,
			match_id: match,
			crew: crew !== "" ? crew : null,
			token: localStorage.getItem("token")
		})
	}).then((response) => {
		if (response.status === 200) {
			response.json().then((data) => {
				if (data.error) {
					setError(data.error);
					if (data.error === "Invalid token") {
						localStorage.removeItem("token");
						window.location.href = "/login";
					}

					return;
				}

				const globalData: IGlobalData = {
					total_kills: data.leaderboard.reduce((acc: any, curr: any) => acc + curr.kills, 0)
				};

				setData({ leaderboard: data.leaderboard, globalData });
				setError("");

				console.log(data, globalData)
			});
		} else {
			setError(response.statusText);
		}
	});
}


function exportDiscordFormat(data: IData = { leaderboard: [], globalData: { total_kills: 0 } }) {
	let result = "";
	for (let i = 0; i < data.leaderboard.length; i++) {
		result += `${i + 1}. ${data.leaderboard[i].killer_name}: ${data.leaderboard[i].kills} kills\n`;
	}

	return result;
}

function getRows(data: IData = { leaderboard: [], globalData: { total_kills: 0 } }) {
	const kills = data.leaderboard;
	const rows = [];

	for (let i = 0; i < data.leaderboard.length; i++) {
		rows.push(
			<Table.Tr key={i}>
				<Table.Td>
					<Anchor component="button" fz="sm">
						{i + 1}
					</Anchor>
				</Table.Td>
				<Table.Td>{kills[i].killer_name}</Table.Td>
				<Table.Td>{kills[i].kills}</Table.Td>
				<Table.Td>
					<Group justify="space-between">
						<Text fz="xs" c="teal" fw={700}>
							{Math.round((kills[i].kills / data.globalData.total_kills) * 100)}%
						</Text>
						<Text fz="xs" c="red" fw={700}>
							{Math.round(((data.globalData.total_kills - kills[i].kills) / data.globalData.total_kills) * 100)}%
						</Text>
					</Group>
					<Progress.Root>
						<Progress.Section
							className={classes.progressSection}
							value={Math.round((kills[i].kills / data.globalData.total_kills) * 100)}
							color="teal"
						/>

						<Progress.Section
							className={classes.progressSection}
							value={Math.round(((data.globalData.total_kills - kills[i].kills) / data.globalData.total_kills) * 100)}
							color="red"
						/>
					</Progress.Root>
				</Table.Td>
			</Table.Tr>
		);
	}
	return rows;
}

export default function Leaderboard({ user = null }: { user: IUser | null }) {
	const [type, setType] = useState<string>("actual");
	const [crew, setCrew] = useState<string>("");
	const [match, setMatch] = useState<number | string>(0);
	const [data, setData] = useState<IData>({ leaderboard: [], globalData: { total_kills: 0 } });
	const [error, setError] = useState<string>("");

	useEffect(() => {
		console.log(type)
		console.log(match)
		console.log(crew)
		// fillDummyData(setData);
	}, [type, crew, match])

	if (!user?.username)
		return <Error401 />;

	return (
		<div className={classes.Leaderboard}>
			<h1>Leaderboard</h1>

			{error !== "" && (
				<Notification className={classes.Notification} color="red" title="Error" onClose={() => setError("")}>
					{error}
				</Notification>
			)}

			<Grid className={classes.Content}>
				<Grid.Col className={classes.Side} span={{ base: 12, xs: 4 }}>
					<Select
						label="Type"
						placeholder="Pick value"
						data={['actual', 'total', 'specific']}
						value={type}
						allowDeselect={false}
						onChange={(event: any) => setType(event)}
					/>
					{type === "specific" && (
						<NumberInput
							label="Match ID"
							placeholder="1"
							required
							value={match}
							onChange={(event) => setMatch(event)}
						/>
					)}
					<TextInput
						label="Crew name"
						placeholder="Leave empty if all crews"
						value={crew}
						onChange={(event) => setCrew(event.currentTarget.value)}
						autoComplete="nope"
					/>
					<Button
						variant="light"
						color="teal"
						className={classes.Button}
						onClick={() => {
							getLeaderboard(setData, setError, type, match, crew);
						}}
						mt="xs"
					>
						Search
					</Button>
				</Grid.Col>
				<Grid.Col className={classes.List} span={{ base: 12, xs: 8 }}>
					<ScrollArea h={"100%"} scrollHideDelay={2}>
						<Table verticalSpacing="xs">
							<Table.Thead>
								<Table.Tr>
									<Table.Th>N°</Table.Th>
									<Table.Th>Player</Table.Th>
									<Table.Th>Kills</Table.Th>
									<Table.Th className={classes.ExportColumn}>
										% of the kills
										<Tooltip label="Export to discord format" color="teal">
											<IconFileExport className={classes.HoverEffect} onClick={
												() => {
													navigator.clipboard.writeText(exportDiscordFormat(data));
												}
											} />
										</Tooltip>
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>{getRows(data)}</Table.Tbody>
						</Table>
					</ScrollArea>
				</Grid.Col>
			</Grid>
		</div>
	);
}