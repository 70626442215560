import {
	TextInput,
	PasswordInput,
	Checkbox,
	Anchor,
	Paper,
	Title,
	Text,
	Container,
	Group,
	Button,
} from '@mantine/core';
import { Link } from 'react-router-dom';

import classes from './Register.module.css';

export default function RegisterComponent() {
	return (
		<Container size={420} my={40}>
			<Title ta="center" className={classes.title}>
				Create an account
			</Title>
			<Text c="dimmed" size="sm" ta="center" mt={5}>
				You already have an account?{' '}
				<Anchor to="/login"  size="sm" component={Link}>
					Login
				</Anchor>
			</Text>

			<Paper withBorder shadow="md" p={30} mt={30} radius="md">
				<TextInput label="Email" placeholder="you@mantine.dev" required />
				<PasswordInput label="Password" placeholder="Your password" required mt="md" />
				<PasswordInput label="Repeat password" placeholder="Your password" required mt="md" />
				<Group justify="space-between" mt="lg">
					<Checkbox label="Confimation" />
				</Group>
				<Button fullWidth mt="xl">
					Sign up
				</Button>
			</Paper>
		</Container>
	)
}