import { Anchor, Button, Checkbox, Grid, Group, Notification, NumberInput, Progress, ScrollArea, Table, Text, TextInput, Tooltip } from '@mantine/core';
import classes from './Kills.module.css';
import { useEffect, useState } from 'react';
import { IconFileExport } from '@tabler/icons-react';
import { IUser } from '../../utils/types';
import Error401 from '../../components/Error401';
import { useHover } from '@mantine/hooks';

interface IKills {
	killer_crew: string;
	kills: number;
}

interface IGlobalData {
	total_kills: number;
}

interface IData {
	kills: IKills[];
	globalData: IGlobalData;
}

function fillDummyData(setData: React.Dispatch<React.SetStateAction<IData>>) {
	const kills: IKills[] = [
		{ killer_crew: "MGZ", kills: 150 },
		{ killer_crew: "NL", kills: 100 },
		{ killer_crew: "Peaky", kills: 50 },
		{ killer_crew: "EDG", kills: 25 }
	];

	const globalData: IGlobalData = {
		total_kills: kills.reduce((acc, curr) => acc + curr.kills, 0)
	};

	setData({ kills, globalData });
}

function getKills(setData: React.Dispatch<React.SetStateAction<IData>>, setError: React.Dispatch<React.SetStateAction<string>>, zone: string, actualZone: boolean, match: number | string) {
	if (zone === "") {
		setError("Zone name is required");
		return;
	}

	fetch("https://bycop.fr:3638/kills", {
		method: "POST",
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			zone_id: zone,
			match_id: !actualZone ? match : null,
			token: localStorage.getItem("token")
		})
	}).then((response) => {
		if (response.status === 200) {
			response.json().then((data) => {
				if (data.error) {
					setError(data.error);
					if (data.error === "Invalid token") {
						localStorage.removeItem("token");
						window.location.href = "/login";
					}

					return;
				}

				const globalData: IGlobalData = {
					total_kills: data.kills.reduce((acc: any, curr: any) => acc + curr.kills, 0)
				};

				setData({ kills: data.kills, globalData });
				setError("");

				console.log(data, globalData)
			});
		} else {
			setError(response.statusText);
		}
	});
}

function exportDiscordFormat(data: IData = { kills: [], globalData: { total_kills: 0 } }) {
	const prefixes = ["st", "nd", "rd", "th"];

	let result = "";
	for (let i = 0; i < data.kills.length; i++) {
		result += `${i + 1}${prefixes[i >= 3 ? 3 : i]}: **${data.kills[i].killer_crew}** with ${data.kills[i].kills} kills\n`;
	}

	return result;
}

function getRows(data: IData = { kills: [], globalData: { total_kills: 0 } }) {
	const kills = data.kills;
	const rows = [];

	for (let i = 0; i < data.kills.length; i++) {
		rows.push(
			<Table.Tr key={i}>
				<Table.Td>
					<Anchor component="button" fz="sm">
						{i + 1}
					</Anchor>
				</Table.Td>
				<Table.Td>{kills[i].killer_crew}</Table.Td>
				<Table.Td>{kills[i].kills}</Table.Td>
				<Table.Td>
					<Group justify="space-between">
						<Text fz="xs" c="teal" fw={700}>
							{Math.round((kills[i].kills / data.globalData.total_kills) * 100)}%
						</Text>
						<Text fz="xs" c="red" fw={700}>
							{Math.round(((data.globalData.total_kills - kills[i].kills) / data.globalData.total_kills) * 100)}%
						</Text>
					</Group>
					<Progress.Root>
						<Progress.Section
							className={classes.progressSection}
							value={Math.round((kills[i].kills / data.globalData.total_kills) * 100)}
							color="teal"
						/>

						<Progress.Section
							className={classes.progressSection}
							value={Math.round(((data.globalData.total_kills - kills[i].kills) / data.globalData.total_kills) * 100)}
							color="red"
						/>
					</Progress.Root>
				</Table.Td>
			</Table.Tr>
		);
	}
	return rows;
}

export default function Kills({ user = null }: { user: IUser | null }) {
	const [zone, setZone] = useState<string>("");
	const [actualZone, setActualZone] = useState<boolean>(true);
	const [match, setMatch] = useState<number | string>(0);
	const [data, setData] = useState<IData>({ kills: [], globalData: { total_kills: 0 } });
	const [error, setError] = useState<string>("");

	useEffect(() => {
		console.log(zone)
		console.log(actualZone)
		console.log(match)
		// fillDummyData(setData);
	}, [zone, actualZone, match])

	if (!user?.username)
		return <Error401 />;

	return (
		<div className={classes.Kills}>
			<h1>Kills</h1>

			{error !== "" && (
				<Notification className={classes.Notification} color="red" title="Error" onClose={() => setError("")}>
					{error}
				</Notification>
			)}

			<Grid className={classes.Content}>
				<Grid.Col className={classes.Side} span={{ base: 12, xs: 4 }}>
					<TextInput
						label="Zone name"
						placeholder="madrazo,taxi soccer, ..."
						required
						value={zone}
						onChange={(event) => setZone(event.currentTarget.value)}
						autoComplete="nope"
					/>
					<Checkbox
						checked={actualZone}
						onChange={(event) => setActualZone(event.currentTarget.checked)}
						label="Actual match"
						mt="xs"
					/>
					{!actualZone && (
						<NumberInput
							label="Match ID"
							placeholder="1"
							required
							value={match}
							onChange={(event) => setMatch(event)}
						/>
					)}
					<Button
						variant="light"
						color="teal"
						className={classes.Button}
						onClick={() => {
							getKills(setData, setError, zone, actualZone, match);
						}}
						mt="xs"
					>
						Search
					</Button>
				</Grid.Col>
				<Grid.Col className={classes.List} span={{ base: 12, xs: 8 }}>
					<ScrollArea h={"100%"} scrollHideDelay={2}>
						<Table verticalSpacing="xs">
							<Table.Thead>
								<Table.Tr>
									<Table.Th>N°</Table.Th>
									<Table.Th>Crew</Table.Th>
									<Table.Th>Kills</Table.Th>
									<Table.Th className={classes.ExportColumn}>
										% of the kills
										<Tooltip label="Export to discord format" color="teal">
											<IconFileExport className={classes.HoverEffect} onClick={
												() => {
													navigator.clipboard.writeText(exportDiscordFormat(data));
												}
											} />
										</Tooltip>
									</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>{getRows(data)}</Table.Tbody>
						</Table>
					</ScrollArea>
				</Grid.Col>
			</Grid>
		</div>
	);
}