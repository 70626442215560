import {
  Group,
  Button,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  Image,
  Text,
  Avatar,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './Header.module.css';
import { Link } from 'react-router-dom'
import ExtinctionLogo from '../../assets/extinction.png';
import { IUser } from '../../utils/types';

export default function Header({ user = null }: { user: IUser | null }) {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const isLogged = user?.username;

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  return (
    <Box>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Group h="100%" visibleFrom="sm">
            <Link to="/" className={classes.link}>
              <Image src={ExtinctionLogo} h={30} />
              <Text fw={500} size="xl">&nbsp;Extinction Cup</Text>
            </Link>
          </Group>

          <Group h="100%" gap={0} visibleFrom="sm">
            <Link to="/" className={`${classes.linkspacer} ${classes.link}`}>
              Home
            </Link>
            <Link to="/kills" className={`${classes.linkspacer} ${classes.link}`}>
              Kills
            </Link>
            <Link to="/leaderboard" className={`${classes.linkspacer} ${classes.link}`}>
              Leaderboard
            </Link>
            <Link to="/toolid" className={`${classes.linkspacer} ${classes.link}`}>
              Tool
            </Link>
            <Link to="/settings" className={`${classes.linkspacer} ${classes.link}`}>
              Settings
            </Link>
          </Group>

          {!isLogged ?
            <Group visibleFrom="sm">
              <Button component={Link} to="/login" variant="default">Log in</Button>
              <Button component={Link} to="/register">Sign up</Button>
            </Group>
            : <Group visibleFrom="sm">
              <Avatar src={user.avatar} alt="User avatar" />
              <Button variant="default" onClick={handleLogout}>Log out</Button>
            </Group>
          }

          <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <Link to="/" className={`${classes.linkspacer} ${classes.link}`}>
            Home
          </Link>
          <Link to="/kills" className={`${classes.linkspacer} ${classes.link}`}>
            Kills
          </Link>
          <Link to="/leaderboard" className={`${classes.linkspacer} ${classes.link}`}>
            Leaderboard
          </Link>
          <Link to="/toolid" className={`${classes.linkspacer} ${classes.link}`}>
            Tool
          </Link>
          <Link to="/settings" className={`${classes.linkspacer} ${classes.link}`}>
            Settings
          </Link>

          <Divider my="sm" />

          {!isLogged ?
            <Group justify="center" grow pb="xl" px="md">
              <Button component={Link} to="/login" variant="default">Log in</Button>
              <Button component={Link} to="/register">Sign up</Button>
            </Group>
            : <Group justify="center" grow pb="xl" px="md">
              <Avatar src={user.avatar} alt="User avatar" />
              <Button variant="default" onClick={handleLogout}>Log out</Button>
            </Group>
          }
        </ScrollArea>
      </Drawer>
    </Box>
  );
}