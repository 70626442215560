import classes from './Error401.module.css'

function Error401() {
    return (
        <div className={classes.Error}>
            <h1>Error 401: You need to be logged</h1>
        </div>
    )
}
 
export default Error401