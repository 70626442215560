import {
	TextInput,
	PasswordInput,
	Checkbox,
	Anchor,
	Paper,
	Title,
	Text,
	Container,
	Group,
	Button,
	Notification,
} from '@mantine/core';
import { Link } from 'react-router-dom';

import classes from './Login.module.css';
import { accounts } from '../../utils/dev';
import { useState } from 'react';

export default function LoginComponent() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleLogin = () => {

		if (!username || !password) {
			setError('Please fill all fields');
			return;
		}
	
		// Post to /login
		fetch('https://bycop.fr:3638/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username, password }),
		})
			.then((response) => {
				if (response.status === 200) {
					response.json().then((data) => {
						console.log(data)
						if (data.error) {
							console.error(data.error);
							setError(data.error);
							return;
						} else if (data.token) {
							localStorage.setItem('token', data.token);
							window.location.href = '/';
						}
					});
				} else {
					response.json().then((data) => {
						console.error(data.error);
					});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	return (
		<Container size={420} my={40}>
			{error !== "" && (
				<Notification className={classes.Notification} color="red" title="Error" onClose={() => setError("")}>
					{error}
				</Notification>
			)}
			<Title ta="center" className={classes.title}>
				Welcome back!
			</Title>
			<Text c="dimmed" size="sm" ta="center" mt={5}>
				Do not have an account yet?{' '}
				<Anchor to="/register" size="sm" component={Link}>
					Create account
				</Anchor>
			</Text>

			<Paper withBorder shadow="md" p={30} mt={30} radius="md">
				<TextInput label="Username" placeholder="Xx_noob_du_66_xX" onChange={(e) => setUsername(e.currentTarget.value)} required />
				<PasswordInput label="Password" placeholder="Your password" onChange={(e) => setPassword(e.currentTarget.value)} required mt="md" />
				<Group justify="space-between" mt="lg">
					<Checkbox label="Remember me" />
					<Anchor to="/reset-password" component={Link} size="sm">
						Forgot password?
					</Anchor>
				</Group>
				<Button fullWidth mt="xl" onClick={handleLogin}>
					Sign in
				</Button>
			</Paper>
		</Container>
	)
}