import classes from './Home.module.css';

function Home() {
	return (
		<div className={classes.Home}>
			<h1>Home</h1>

		</div>
	);
}

export default Home